import { useEffect, useContext, Suspense, lazy, useRef } from "react";
import { useLocation, Switch, Route, Redirect } from "react-router";
import { SidebarContext } from "../context/SidebarContext";
import Main from "./Main";
import routes from "../../routes/Index";
import Sidebar from "../navigation/Sidebar";
import FallbackPage from "../basicStructure/FallbackPage";
import Navbar from "../navigation/Navbar";

const Page404 = lazy(() => import("../../pages/Page404"));

const Layout = () => {
  const { isSidebarOpen, closeSidebar } = useContext(SidebarContext);
  let location = useLocation();

  const closeSidebarRef = useRef(closeSidebar)

  useEffect(() => {
    closeSidebarRef.current();
  }, [location]);

  return (
    <div
      className={`flex h-screen bg-gray-50 dark:bg-gray-900 ${
        isSidebarOpen && "overflow-hidden"
      }`}
    >
      <Sidebar />

      <div className="flex flex-col flex-1 w-full">
        <Navbar />
        <Main>
          <Suspense fallback={<FallbackPage />}>
            <Switch>
              {routes.map((route, i) => {
                return route.component ? (
                  <Route
                    key={i}
                    exact={true}
                    path={`${route.path}`}
                    render={(props) => <route.component {...props} />}
                  />
                ) : null;
              })}
              <Redirect exact from="/" to="/home" />
              <Route component={Page404} />
            </Switch>
          </Suspense>
        </Main>
      </div>
    </div>
  );
};

export default Layout;
