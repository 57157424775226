import { useState } from "react";
import { NavLink } from "react-router-dom";
import { auth } from "../../data/Firebase";

const SidebarSubmenu = ({ route, admins }) => {

  const [isOpen, setIsOpen] = useState(false);
  const currentUser = auth.currentUser.email;
  
  return (
    
    <li className="relative px-6 py-3" key={route.name}>
      
      <button
        className="inline-flex items-center justify-between w-full text-lg font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
        onClick={() => setIsOpen(!isOpen)}
      >
        
        <span className="inline-flex items-center">
        
          <div className="w-5 h-5">{route.icon}</div>
          <span className="ml-4">{route.name}</span>
        </span>
        <div className="w-10 h-4">{route.iconDrop}</div>
      </button>
      <div className={` ${!isOpen ?  'transition-all ease-in-out duration-500 opacity-0 invisible' : 'transition-all ease-in-out duration-500 opacity-100'} ` }>
          {isOpen && <ul className='p-2 mt-1 ml-4 text-base font-semibold text-gray-500 dark:text-gray-400'  >
             {route.routes.map((subRoute, i) => {
                 return (
                     <li className='relative px-2 transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200' key={i}>
                        {(subRoute.name !== 'Permisos') ? 
                         <NavLink className='w-full inline-flex items-center space-y-2' to={subRoute.path} key={i} activeClassName="text-gray-800 dark:text-gray-100">
                            <div className='w-5 h-2' >{subRoute.icon}</div>
                            <span className='ml-4' >{subRoute.name}</span>
                         </NavLink> :
                         (subRoute.name === 'Permisos' && admins.includes(currentUser)) &&
                         <NavLink className='w-full inline-flex items-center space-y-2' to={subRoute.path} key={i} activeClassName="text-gray-800 dark:text-gray-100">
                            <div className='w-5 h-2' >{subRoute.icon}</div>
                            <span className='ml-4' >{subRoute.name}</span>
                         </NavLink>
                        }
                     </li>
                 )
             })} 
          </ul>}
      </div>
    </li>
  );
};

export default SidebarSubmenu;
