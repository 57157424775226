import  { useContext } from 'react'
import { SidebarContext } from '../context/SidebarContext'

import * as MaterialsIcons from "react-icons/bi";
import ThemeToggle from './ThemeToggle';
import { auth } from '../../data/Firebase';


function Navbar() {

  const { toggleSidebar } = useContext(SidebarContext)



  return (
    <div className="z-40 py-4 bg-white shadow-bottom dark:bg-gray-800 ">
      <div className="container flex items-center justify-between h-full px-6 mx-auto text-purple-600 dark:text-purple-300">
       

          <button
            className="p-1 mr-5 -ml-1 rounded-md lg:hidden focus:outline-none focus:shadow-outline-purple"
            onClick={toggleSidebar}
            aria-label="Menu"
          >
            <MaterialsIcons.BiMenu className="w-6 h-6" aria-hidden="true" />
          </button>
        
        
        
        <div className="container flex items-center justify-end px-6  text-purple-600 dark:text-purple-300">

            <ThemeToggle />
        </div>
        <span className='text-xs'>{auth.currentUser.email}</span>
      </div>
    </div>
  )
}

export default Navbar
