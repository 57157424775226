import { ThemeContext } from '../context/ThemeContext'
import { useContext } from 'react';
import * as MaterialsIcons from "react-icons/bi";

const ThemeToggle = () => {
    const { theme, setTheme } = useContext(ThemeContext);

    return (
        <div className="flex flex-shrink-0 space-x-6 transition duration-500 ease-in-out p-2  ">
            {theme === 'dark' ? (
                <MaterialsIcons.BiSun
                    onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
                    className="rounded-md focus:outline-none focus:shadow-outline-purple text-2xl cursor-pointer"
                />
            ) : (
                    <MaterialsIcons.BiMoon
                        onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
                        className="rounded-md focus:outline-none focus:shadow-outline-purple text-2xl cursor-pointer"
                    />
                )}
        </div>
    );
};

export default ThemeToggle;