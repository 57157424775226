import { lazy } from 'react';

const Home = lazy(() => import('../pages/Home'));
const Expenses = lazy(() => import('../pages/Expenses'));
const ExpensesList = lazy(() => import('../pages/ExpensesList'));
const NewExpensesList = lazy(() => import('../pages/NewExpensesList'));
const Stock = lazy(() => import('../pages/Stock'));
const StockDetails = lazy(() => import('../pages/StockDetails'));
const Sales = lazy(() => import('../pages/Sales'));
const SalesInternal = lazy(() => import('../pages/SalesInternal'));
const SalesList = lazy(() => import('../pages/SalesList'));
const Providers = lazy(() => import('../pages/Providers'));
const ProvidersList = lazy(() => import('../pages/ProvidersList'));
const Employees = lazy(() => import('../pages/Employees'));
const EmployeesDetails = lazy(() => import('../pages/EmployeesDetails'));
const EmployeesList = lazy(() => import('../pages/EmployeesList'));
const Machinery = lazy(() => import('../pages/Machinery'));
const MachineryDetails = lazy(() => import('../pages/MachineryDetails'));
const MachineryList = lazy(() => import('../pages/MachineryList'));
const Buildings = lazy(() => import('../pages/Buildings'));
const BuildingsDetails = lazy(() => import('../pages/BuildingsDetails'));
const BuildingsList = lazy(() => import('../pages/BuildingsList'));
const Cattle = lazy(() => import('../pages/Cattle'));
const CattleDestiny = lazy(() => import('../pages/CattleDestiny'));
const CattleHerdExpense = lazy(() => import('../pages/CattleHerdExpense'));
const CattleHerdManager = lazy(() => import('../pages/CattleHerdManager'));
const CattleDetails = lazy(() => import('../pages/CattleDetails'));
const CattleList = lazy(() => import('../pages/CattleList'));
const Reports = lazy(() => import('../pages/Reports'));
const ReportsDetails = lazy(() => import('../pages/ReportsDetails'));
const Rain = lazy(() => import('../pages/Rain'));
const LogOut = lazy(() => import('../pages/Logout'));
const Permissions = lazy(() => import('../pages/Permissions'));
const Configuration = lazy(() => import('../pages/Configuration'));
const Rectification = lazy(() => import('../pages/Rectification'));
const AdministrationDestiny = lazy(() =>
  import('../pages/AdministrationDestiny')
);

const routes = [
  {
    path: '/home',
    component: Home,
  },
  {
    path: '/expenses',
    component: Expenses,
  },
  {
    path: '/expenses/:id',
    component: Expenses,
  },
  {
    path: '/expenseslist',
    component: ExpensesList,
  },
  {
    path: '/expenseslistv3',
    component: NewExpensesList,
  },
  {
    path: '/stock',
    component: Stock,
  },
  {
    path: '/stockdetails/:id',
    component: StockDetails,
  },
  {
    path: '/sales',
    component: Sales,
  },
  {
    path: '/sales/:id',
    component: Sales,
  },
  {
    path: '/internalsales',
    component: SalesInternal,
  },
  {
    path: '/internalsales/:id',
    component: SalesInternal,
  },
  {
    path: '/saleslist',
    component: SalesList,
  },
  {
    path: '/providers',
    component: Providers,
  },
  {
    path: '/providers/:id',
    component: Providers,
  },
  {
    path: '/providerslist',
    component: ProvidersList,
  },
  {
    path: '/employees',
    component: Employees,
  },
  {
    path: '/employees/:id',
    component: Employees,
  },
  {
    path: '/employeesdetails/:id',
    component: EmployeesDetails,
  },
  {
    path: '/employeeslist',
    component: EmployeesList,
  },
  {
    path: '/machinery',
    component: Machinery,
  },
  {
    path: '/machinery/:id',
    component: Machinery,
  },
  {
    path: '/machinerydetails/:id',
    component: MachineryDetails,
  },
  {
    path: '/machinerylist',
    component: MachineryList,
  },
  {
    path: '/buildings',
    component: Buildings,
  },
  {
    path: '/buildings/:id',
    component: Buildings,
  },
  {
    path: '/buildingsdetails/:id',
    component: BuildingsDetails,
  },
  {
    path: '/buildingslist',
    component: BuildingsList,
  },
  {
    path: '/cattle',
    component: Cattle,
  },
  {
    path: '/cattle/:id',
    component: Cattle,
  },
  {
    path: '/cattleherdexpense',
    component: CattleHerdExpense,
  },
  {
    path: '/cattleherdexpense/:id',
    component: CattleHerdExpense,
  },
  {
    path: '/cattleherdmanager',
    component: CattleHerdManager,
  },
  {
    path: '/cattledetails',
    component: CattleDetails,
  },
  {
    path: '/cattlelist',
    component: CattleList,
  },
  {
    path: '/cattledestiny',
    component: CattleDestiny,
  },
  {
    path: '/reports',
    component: Reports,
  },
  {
    path: '/reportsdetails',
    component: ReportsDetails,
  },
  {
    path: '/rain',
    component: Rain,
  },
  {
    path: '/logout',
    component: LogOut,
  },
  {
    path: '/permissions',
    component: Permissions,
  },
  {
    path: '/config',
    component: Configuration,
  },
  {
    path: '/rectification',
    component: Rectification,
  },
  {
    path: '/administrativedestiny',
    component: AdministrationDestiny,
  },
];

export default routes;
