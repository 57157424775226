import { useHistory } from "react-router-dom";
import Label from "../components/formsStructure/Label";
import { auth } from "../data/Firebase";
import { signInWithEmailAndPassword, sendPasswordResetEmail} from 'firebase/auth'
import ImageLight from "../assets/ganado2.jpg";
import ImageDark from "../assets/ganado2.jpg";
import { useState } from "react";
import InputCustom from "../components/formsStructure/InputCustom";



const Login = () => {
    
    const [nameAndPass, setNameAndPass] = useState({});
    const [loading, setLoading] = useState(false);
    
  const history = useHistory();

  const handleClick = async () => {
    const mail = nameAndPass.mail
    const pass = nameAndPass.pass
    setLoading(true);
    try {
        await signInWithEmailAndPassword(auth, mail, pass);
        history.push('/home')
        return
    } catch(e) {
        alert('Usuario o password incorrecto');  
    }
    setLoading(false);
    return;
}
  const handleEnter = async (e) => {
    if (e.key === 'Enter') {

        const mail = nameAndPass.mail
        const pass = nameAndPass.pass
        setLoading(true);
        try {
            await signInWithEmailAndPassword(auth, mail, pass);
            history.push('/home')
            return
        } catch(e) {
            alert('Usuario o password incorrecto');  
        }
        setLoading(false);
        return;
    }
}

  const onChange = (e) => {
    const value = e.target.value
    const name = e.target.name
    nameAndPass[name] = value;
    setNameAndPass({...nameAndPass})

  }

  const handlePassReset = async () => {
    try {
        await sendPasswordResetEmail(auth, nameAndPass.mail)
        alert('Un e-mail ha sido enviado a la cuenta, favor revisar')
    } catch(e) {
        alert('Datos incorrectos');
        return
    }
  }
  return (
    <>
      <div className="flex items-center min-h-screen p-6 bg-white  dark:bg-gray-900">
        <div className="flex-1 max-w-4xl mx-auto overflow-hidden bg-white rounded-lg shadow-xl dark:bg-gray-800">
          <div className="flex flex-col overflow-y-auto md:flex-row">
            <div className="h-32 md:h-auto md:w-1/2">
              <img
                aria-hidden="true"
                className="object-cover sm:object-left w-full h-full dark:hidden"
                src={ImageLight}
                alt="Office"
              />
              <img
                aria-hidden="true"
                className="hidden object-cover w-full h-full dark:block"
                src={ImageDark}
                alt="Office"
              />
            </div>
            <main className="flex items-center justify-center p-6 sm:p-12 md:w-1/2">
              <div className="w-full">
                <h1 className="mb-4 text-xl font-semibold text-gray-700 dark:text-gray-200">
                  Login
                </h1>
                <Label>
                  <span>Email</span>
                  <InputCustom type="email" placeholder="name@email.com" name='mail' value={nameAndPass.mail || ''} onChange={onChange}/>
                </Label>

                <Label className="mt-4">
                  <span>Password</span>
                  <InputCustom type="password" placeholder="***************" name='pass' value={nameAndPass.pass || ''}  onChange={onChange} onKeyDown={(e) => handleEnter(e)}/>
                </Label>
                {!loading ? 
                <button
                  className="font-medium w-full leading-5 border-none bg-purple-600 rounded-lg text-white border py-2 px-3 hover:bg-purple-800 focus:outline-none"
                  onClick={handleClick}
                  
                >
                  Log In
                </button> : <button
                  className="font-medium w-full leading-5 border-none bg-purple-600 rounded-lg text-white border py-2 px-3 hover:bg-purple-800 focus:outline-none"
                  disabled={loading}
                >Loading</button>
                }
                <hr className="my-8" />

                <p className="mt-4">
                  <button
                    className="text-sm font-medium text-purple-600 dark:text-purple-400 hover:underline"
                    onClick={handlePassReset}
                  >
                    Olvidaste el password?
                  </button>
                </p>
                
              </div>
            </main>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
