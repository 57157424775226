import { useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom'
import Layout from './components/containers/Layout';
import Login from './pages/Login';
import { auth } from './data/Firebase';
import { onAuthStateChanged} from 'firebase/auth'



function App() {
 
  const [isLogged, setIsLogged] = useState(null)



  onAuthStateChanged(auth, userFb => {
    if (userFb) {
      setIsLogged(userFb)
    } else {
      setIsLogged(null);
    }
    
  })
 
  return (
    <Router>
      {isLogged ? <Layout /> : <Login />}      
    </Router>
  );
}

export default App;
