import * as MaterialsIcons from 'react-icons/bi';
import {
  GiCow,
  GiBull,
  GiFarmTractor,
  GiGearHammer,
  GiBarn,
  GiGranary,
  GiFarmer,
  GiCavalry,
  GiGears,
  GiChecklist,
  GiCentaur,
  GiBookshelf,
  GiOpenBook,
} from 'react-icons/gi';

const routes = [
  {
    path: '/home',
    icon: <MaterialsIcons.BiHome />,
    name: 'Home',
  },
  {
    iconDrop: <MaterialsIcons.BiChevronDown />,
    icon: <MaterialsIcons.BiWallet />,
    name: 'Gastos',
    routes: [
      {
        path: '/expenses',
        icon: <MaterialsIcons.BiMoney />,
        name: 'Nuevo Gasto',
      },
      {
        path: '/stock',
        icon: <MaterialsIcons.BiPackage />,
        name: 'Stock',
      },
      {
        path: '/expenseslist',
        icon: <MaterialsIcons.BiReceipt />,
        name: 'Lista de Gastos',
      },
      {
        path: '/expenseslistv3',
        icon: <MaterialsIcons.BiReceipt />,
        name: 'Nueva Lista de Gastos',
      },
    ],
  },
  {
    icon: <MaterialsIcons.BiCoinStack />,
    iconDrop: <MaterialsIcons.BiChevronDown />,
    name: 'Ventas',
    routes: [
      {
        path: '/sales',
        icon: <MaterialsIcons.BiCoin />,
        name: 'Nueva Venta',
      },
      {
        path: '/internalsales',
        icon: <MaterialsIcons.BiTransfer />,
        name: 'Venta Interna',
      },
      {
        path: '/saleslist',
        icon: <MaterialsIcons.BiReceipt />,
        name: 'Lista de Ventas',
      },
    ],
  },
  {
    iconDrop: <MaterialsIcons.BiChevronDown />,
    icon: <MaterialsIcons.BiGroup />,
    name: 'Proveedores',
    routes: [
      {
        path: '/providers',
        icon: <MaterialsIcons.BiUserPlus />,
        name: 'Nuevo Proveedor',
      },
      {
        path: '/providerslist',
        icon: <MaterialsIcons.BiReceipt />,
        name: 'Lista de Proveedores',
      },
    ],
  },
  {
    iconDrop: <MaterialsIcons.BiChevronDown />,
    icon: <GiFarmer />,
    name: 'Personales',
    routes: [
      {
        path: '/employees',
        icon: <MaterialsIcons.BiUserPlus />,
        name: 'Nuevo Personal',
      },
      {
        path: '/employeeslist',
        icon: <MaterialsIcons.BiReceipt />,
        name: 'Lista de Personales',
      },
    ],
  },
  {
    iconDrop: <MaterialsIcons.BiChevronDown />,
    icon: <GiFarmTractor />,
    name: 'Maquinarias',
    routes: [
      {
        path: '/machinery',
        icon: <GiGearHammer />,
        name: 'Nueva Maquinaria',
      },
      {
        path: '/machinerylist',
        icon: <MaterialsIcons.BiReceipt />,
        name: 'Lista de Maquinarias',
      },
    ],
  },
  {
    iconDrop: <MaterialsIcons.BiChevronDown />,
    icon: <GiBarn />,
    name: 'Infraestructura',
    routes: [
      {
        path: '/buildings',
        icon: <GiGranary />,
        name: 'Nueva Infra',
      },
      {
        path: '/buildingslist',
        icon: <MaterialsIcons.BiReceipt />,
        name: 'Lista de Infra',
      },
    ],
  },
  {
    iconDrop: <MaterialsIcons.BiChevronDown />,
    icon: <GiCow />,
    name: 'Ganado',
    routes: [
      {
        path: '/cattledestiny',
        icon: <GiCentaur />,
        name: 'Nuevo Destino',
      },
      {
        path: '/cattleherdexpense',
        icon: <GiCavalry />,
        name: 'Compra Tropa',
      },
      {
        path: '/cattleherdmanager',
        icon: <GiCavalry />,
        name: 'Administrar Tropa',
      },
      {
        path: '/cattle',
        icon: <GiBull />,
        name: 'Administrar Caravana',
      },
      {
        path: '/cattlelist',
        icon: <MaterialsIcons.BiReceipt />,
        name: 'Lista de ganado',
      },
    ],
  },
  {
    iconDrop: <MaterialsIcons.BiChevronDown />,
    icon: <GiBookshelf />,
    name: 'Administrativo',
    routes: [
      {
        path: '/administrativedestiny',
        icon: <GiOpenBook />,
        name: 'Nuevo Destino',
      },
    ],
  },
  {
    path: '/reports',
    icon: <MaterialsIcons.BiLineChart />,
    name: 'Reportes',
  },
  {
    path: '/rain',
    icon: <MaterialsIcons.BiDroplet />,
    name: 'Precipitaciones',
  },
  {
    icon: <MaterialsIcons.BiLockOpenAlt />,
    iconDrop: <MaterialsIcons.BiChevronDown />,
    name: 'Autenticacion',
    routes: [
      {
        path: '/logout',
        icon: <MaterialsIcons.BiLogOut />,
        name: 'Log Out',
      },
      {
        path: '/permissions',
        icon: <MaterialsIcons.BiUserCheck />,
        name: 'Permisos',
      },
    ],
  },
  {
    icon: <MaterialsIcons.BiWrench />,
    iconDrop: <MaterialsIcons.BiChevronDown />,
    name: 'Utiles',
    routes: [
      {
        path: '/config',
        icon: <GiGears />,
        name: 'Configuracion',
      },
      {
        path: '/rectification',
        icon: <GiChecklist />,
        name: 'Rectificador de Gastos',
      },
    ],
  },
];

export default routes;
export * as MaterialsIcons from 'react-icons/bi';
