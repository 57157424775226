

const InputCustom = (props) => {
    return (
        <input className={`${props.custom || 'w-full'}   text-sm focus:outline-none dark:text-gray-300 form-textarea leading-5 focus:border-purple-400 dark:border-gray-600 focus:shadow-outline-purple dark:focus:border-gray-600 dark:focus:shadow-outline-gray dark:bg-gray-700 mt-4 mb-8 rounded-md` }
               placeholder={props.placeholder}
               value={props.value}
               type={props.type || "text"} 
               onChange={props.onChange}
               name={props.name}
               onKeyDown={props.onKeyDown}
               title={props.title}
        />
    )
}

export default InputCustom;