import routes from "../../routes/SidebarRoutes";
import { NavLink } from "react-router-dom";
import SidebarSubmenu from "./SidebarSubmenu";
import {useEffect, useState} from 'react'
import {  doc, getDoc } from "firebase/firestore";
import db from '../../data/Firebase'



const SidebarContent = () => {
  const [admins, setAdmins] = useState([]);
  
  useEffect(() => {
   
    const docGetter = async (docref) => {
        const docSnap = await getDoc(docref);
        if (docSnap.exists()) {
          setAdmins([ ...docSnap.data().admins])
        } else {
          console.log("No such doc");
        }
      };
    const docRef = doc(db, "admins", 'users');
    docGetter(docRef);

    
    return () => {
       
    }
  }, []);


  return (
    <div className="py-4 text-gray-500 dark:text-gray-400">
      <h1 className="ml-6 text-xl font-bold text-gray-800 dark:text-gray-200">
        La Buena Suerte
      </h1>
      <ul className="mt-6">
        {routes.map((route) =>
          route.routes ? (
            <SidebarSubmenu route={route} key={route.name} admins={admins} />
          ) : (
            <li className="relative px-6 py-3" key={route.name}>
              <NavLink
                exact
                to={route.path}
                className="inline-flex items-center w-full text-lg font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
                activeClassName="text-gray-800 dark:text-gray-100"
              >
                
                <div className="w-5 h-5" >
                    {route.icon}
                </div>
                <span className="ml-4">{route.name}</span>
              </NavLink>
            </li>
          )
        )}
      </ul>
    </div>
  );
};

export default SidebarContent;
