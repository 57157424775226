import React, { useContext } from 'react'
import SidebarContent from '../navigation/SidebarContent'



import { SidebarContext } from '../context/SidebarContext'

function MobileSidebar() {
  const { isSidebarOpen } = useContext(SidebarContext)

  return (
    
      <>        
          <aside className={`fixed inset-y-0 z-50 flex-shrink-0 w-64 mt-16 overflow-y-auto transform ${!isSidebarOpen && `-translate-x-full` } transition duration-200 ease-in-out bg-white dark:bg-gray-800 lg:hidden no-scrollbar`}>
            <SidebarContent />
          </aside>
        
      </>
    
  )
}

export default MobileSidebar